<template>
  <div>
    <div class="top">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item,i) in tabarr" :key="i" :label="item.label" :name="item.name"></el-tab-pane>
      </el-tabs>
      <div class="moreBox pointer" @click="handleMore">
        <span class="more">更多</span>
        <i class="el-icon-arrow-right color128144"></i>
      </div>
    </div>
    <div class="itemBox" v-loading="loading" >
      <div v-for="(item,i) in valueArr" :key='i' class="item">
        <div @click="goDetail(item)">
          <span class="core"></span>
          <span class="tt" :title="item.text">{{item.title | textFormat}}</span>
        </div>
        <div>{{item.created_time | secondFormat("LL")}}</div>
      </div>
      <noDataImg v-if="!loading && valueArr.length == 0"></noDataImg>
    </div>
  </div>
</template>
<script>
export default {
  props:['activetab','tabarr'],
  data(){
    return{
      activeName:"",
      loading:false,
      valueArr:[]
    }
  },
  mounted(){
    this.activeName=this.activetab
    this.getList()
  },
  methods:{
    handleClick(){
      this.getList()
    },
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    async getList() {
      let params = {
        content_type: this.activeName,
        source: this.PJSource,
        nopage: 0,
        limit: 5,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.loading = true;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.loading = false;
      if (res.success) {
        this.valueArr = res.data;
      }

    },
    handleMore() {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: this.activeName * 1,
        },
      });
    }
  },
}
</script>
<style lang="less" scoped>
.top{
  position: relative;
  .moreBox {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 5px 0 5px 19px;
    cursor: pointer;

    .more {
      font-size: 14px;
      color: #128144;
    }
  }
}
.itemBox{
  position: relative;
  padding-top: 9px;
  min-height: 250px;
}
.item{
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  cursor: pointer;
  div:first-child{
    width: 300px;
    display: flex;
    .core{
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #333333;
      border-radius: 3px;
      margin-right: 9px;
      margin-top: 8px;
    }
    .tt{
      width: 300px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .tt:hover{
      color: #0E6A37;
    }
  }
}
/deep/ .el-tabs__item{
  font-size:18px;
  padding-bottom: 42px;
  font-weight: bold;
  color: #333333;
}
/deep/ .el-tabs__nav-wrap::after{
  height: 1px;
  background:#DDDDDD;
}
</style>