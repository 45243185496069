var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"tit"},[_vm._v("合作招募")]),_c('div',{staticClass:"itemBox"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"creditBanner"},[_c('div',{staticClass:"creditBannerImg"},[_c('div',{staticClass:"bannerInner",class:{
          pointer:
            item.link_url &&
            item.extend &&
            !item.extend.is_show,
        },style:({ 'background-image': 'url(' + item.img_url + ')' })},[(
            item.link_url &&
            item.extend &&
            !item.extend.is_show
          )?_c('div',{staticClass:"bannerHerf"},[(
              item.link_url &&
              item.link_url.indexOf('http') == '0'
            )?_c('a',{attrs:{"href":item.link_url,"target":"_blank","rel":"nofollow"}}):_c('a',{attrs:{"href":item.link_url,"rel":"nofollow"}})]):_vm._e(),(item.extend && item.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + item.extend.buttonStyle,style:({
            bottom: `${item.extend.bottom}%`,
            right: `${item.extend.right}%`,
            background: item.extend.backgroundColor,
            borderColor: item.extend.borderColor,
          })},[(
              item.link_url &&
              item.link_url.indexOf('http') == '0'
            )?_c('a',{style:({
              fontSize: `0.${item.extend.fontSize}rem`,
              color: item.extend.color,
            }),attrs:{"href":item.link_url,"target":"_blank","rel":"nofollow"}},[_vm._v(" "+_vm._s(item.extend.content))]):_c('a',{style:({
              fontSize: `0.${item.extend.fontSize}rem`,
              color: item.extend.color,
            }),attrs:{"href":item.link_url,"rel":"nofollow"}},[_vm._v(" "+_vm._s(item.extend.content))])]):_vm._e()])])])}),(!_vm.list.length)?_c('noDataImg'):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }