<template>
  <div class="hzBox">
    <div class="ctitbox flex">
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/win.png" alt="">
      <span>合作共赢</span>
    </div>
    <div class="flex2" >
      <child  :tit="obj1.tit" :bottomtxt="obj1.bottomtxt" :list="obj1.list" :total="obj1.total" :loading="obj1.loading" />
      <child  :tit="obj2.tit" :bottomtxt="obj2.bottomtxt" :list="obj2.list" :total="obj2.total" :loading="obj2.loading" />
      <child  :tit="obj3.tit" :bottomtxt="obj3.bottomtxt" :list="obj3.list" :total="obj3.total" :loading="obj3.loading" />
    </div>
  </div>
</template>
<script>
import child from './child'
export default {
  components:{
    child
  },
  data() {
    return{
      obj1:{
        tit:"校企合作",
        bottomtxt:"了解更多",
        list:[],
        total:0,
        loading:false,
      },
      obj2:{
        tit:"人才库",
        bottomtxt:"前往人才库",
        list:[],
        total:0,
        loading:false,
      },
      obj3:{
        tit:"招聘信息",
        bottomtxt:"更多招聘信息",
        list:[],
        total:0,
        loading:false,
      }
    }
  },
  mounted(){
    this.getList(41)
    this.getList(42)
    this.getjob()
  },
  methods:{
    async getList(activeName) {  //41 校企合作    42 招聘信息
      let params = {
        content_type:activeName,
        source: this.PJSource,
        nopage: 0,
        limit: 5,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      if(activeName==41){
        this.obj1.loading=true
      }
      if(activeName==41){
        this.obj3.loading=true
      }
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.loading = false;
      if (res.success) {
        if(activeName==41){
          this.obj1.total=res.total
          this.obj1.list=res.data
          this.obj1.loading=false
        }
        if(activeName==42){
          this.obj3.total=res.total
          this.obj3.list=res.data
          this.obj3.loading=false
        }
      }
    },
    async getjob(){
      this.obj2.loading=true
      let res=await this.$store.dispatch("API_position/getJobPositionList", {
        start: 0,
        limit: 5,
        is_hot: 1,
        source:this.PJSource
      })
      this.obj2.total=res.total
      this.obj2.list=res.data
      this.obj2.loading=false
    },
  }
}
</script>
<style lang="less" scoped>

.hzBox{
  background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/img_bg.png);
  height: 552px;
}
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex2{
  width: 1200px;
  padding-top:62px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ctitbox{
  padding-top:74px;
  width: 100%;
  span{
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    line-height: 32px;
    padding-left:10px;
  }
}
</style>