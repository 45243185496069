<template>
  <div class="container">
    <div class="itemCenter" id="dynamics">
      <!--轮播图-->
      <carouselBanner  :bannerType="1"></carouselBanner>
      <!--政策法规-->
      <news />
    </div>
    <div class="itemCenter mouseStatus">
      <img v-for="(item,i) in imgArr" :key="i" :src="item.img" alt="" @click="goToPage(item.id)">
    </div>
    <!-- 行业自律 -->
    <hangye class="itemCenter2" style="margin-top:55px"  id="hangye"/>
    <!-- 服务 -->
     <serverbar class="server" id="server" />
    <associationServices class="itemCenter2" style="margin-top:55px"  />
    <!-- 合作共赢 -->
    <cooperationWin id="cooperation"  />
    <!-- 推荐服务 &&  友情链接 -->
    <tuijian />
  </div>
</template>

<script>
import carouselBanner from "~tcb/components/index/home/carouselBanner";
import news from "~tcb/components/index/home/news";
import hangye from "~tcb/components/index/home/hangye/index";
import cooperationWin from "~tcb/components/index/home/cooperationWin";
import tuijian from "~tcb/components/index/home/tuijian";
import serverbar from "~tcb/components/index/home/serverbar";
import associationServices from "~tcb/components/index/home/associationServices";
export default {
  metaInfo: {
    title: "首页",
  },
  components: {
    carouselBanner,
    news,
    hangye,
    associationServices,//协会服务
    tuijian,
    cooperationWin, // 合作共赢
    serverbar
  },
  data() {
    return {
      show_join_us: true,
      dialogVisible:true,
      imgArr:[
        {
          id: 1,
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/three_3.png",
        },
        {
          id: 2,
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/three_12.png",
        },
        {
          id: 3,
          img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/three_1.png",
        }
      ]
    };
  },
  computed: {},
  created() {},
  methods: {
    handle(value) {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: value,
        },
      });
    },
    goApply() {
      this.$router.push("/memberShip");
    },
    goToOut(){
      window.open("https://jinshuju.net/f/uIDjOa?submit_link_generated_from=poster")
    },
    goToPage(type) {
      if(type==1) {
        // 通关协调热线
        // window.open('http://www.baidu.com')
      }else if(type==2) {
        // this.$router.push('/preClass/applyFor')
      } else if(type==3){
        // 法律咨询
      }
    }
  },
};
</script>
<style scoped lang="less">
.pointer {
  cursor: pointer;
}
.container {
  position: relative;
  background: #ffffff;
  .cooperation {
    margin-top: 38px;
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/homeBg2.png");
    height: 215px;
    background-size: 100% 100%;
  }
  .server{
    margin-top: 50px;
  }

}
.itemCenter{
  margin: auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.mouseStatus {
  cursor: pointer;
}
.itemCenter2{
  margin: auto;
  width: 1200px;
}
</style>
