<template>
  <div class="serverbarBox">
    <div class="sev">
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/server.png" alt="">
      <span>服务</span>
    </div>
    <div>
      <el-button type="primary" block class="btn btn1" @click="freeVideo">关务公益讲堂</el-button>
    </div>
    <div>
      <el-button type="primary" block class="btn btn2">知识服务平台</el-button>
    </div>
    <div>
      <el-button type="primary" block class="btn btn3">调研提案窗口</el-button>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{

    }
  },
  methods:{
    freeVideo(){
      this.$router.push('/education')
    }
  }
}
</script>
<style lang="less" scoped>
.serverbarBox{
  width: 1200px;
  margin: auto  ;
  height: 96px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  padding-right:33px;
  padding-left: 24px;
  justify-content: space-between;
}
.sev{
  display: flex;
  align-items: center;
  span{
    padding-left: 8px;
    height: 27px;
    font-size: 28px;
    font-weight: bold;
    color: #222222;
    line-height: 22px;
  }

}
.btn{
  width: 314px;
  height: 64px;
}
.btn1,.btn3{
  background: #F5F5F5!important;
  border: 1px solid #128144;
  color: #128144;

}
</style>