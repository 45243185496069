<template>
  <div class="wrap">
    <div class="tit">合作招募</div>
    <div class="itemBox">
      <div class="creditBanner" v-for="(item,index) in list" :key="index">
        <div class="creditBannerImg">
          <div
              class="bannerInner"
              :class="{
            pointer:
              item.link_url &&
              item.extend &&
              !item.extend.is_show,
          }"
              :style="{ 'background-image': 'url(' + item.img_url + ')' }"
          >
            <div
                class="bannerHerf"
                v-if="
              item.link_url &&
              item.extend &&
              !item.extend.is_show
            "
            >
              <a
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="
                item.link_url &&
                item.link_url.indexOf('http') == '0'
              "
              ></a>
              <a :href="item.link_url" rel="nofollow" v-else></a>
            </div>
            <div
                v-if="item.extend && item.extend.is_show"
                class="bannerBtn"
                :class="'btnStyle' + item.extend.buttonStyle"
                :style="{
              bottom: `${item.extend.bottom}%`,
              right: `${item.extend.right}%`,
              background: item.extend.backgroundColor,
              borderColor: item.extend.borderColor,
            }"
            >
              <a
                  :style="{
                fontSize: `0.${item.extend.fontSize}rem`,
                color: item.extend.color,
              }"
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="
                item.link_url &&
                item.link_url.indexOf('http') == '0'
              "
              >
                {{ item.extend.content }}</a
              >
              <a
                  :href="item.link_url"
                  rel="nofollow"
                  :style="{
                fontSize: `0.${item.extend.fontSize}rem`,
                color: item.extend.color,
              }"
                  v-else
              >
                {{ item.extend.content }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <noDataImg v-if="!list.length"></noDataImg>
    </div>
  </div>
</template>

<script>
export default {
  name: "advertisement",
  data() {
    return {
      list:[]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "14,15,16",
      };
      let res = await this.$store.dispatch(
          "API_index/getAdvertisingList",
          params
      );
      console.log(res)
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
        });
        this.list=res.data
      }
    },
  }
}
</script>

<style scoped lang="less">
.tit {
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #DDDDDD;
}

.itemBox {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 14px;
  position: relative;
  min-height: 250px;

  .creditBanner {
    margin-bottom: 9px;
    width: 100%;
    height: 73px;
    position: relative;
    .creditBannerImg {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;
        .bannerHerf {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          a {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
        .bannerBtn {
          position: absolute;

          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);
          &.btnStyle1 {
            border-radius: 0px;
          }
          &.btnStyle2 {
            border-radius: 4px;
          }
          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }
}
</style>