<template>
  <div class="cBox">
    <h1 class="tit">{{ tit }}</h1>
    <div class="List" v-if="tit!='人才库'" v-loading="loading">
      <div v-for="(item,i) in list" :key='i' class="item" @click="goDetail(item)">
        <span class="core"></span>
        <div class="tit textOverflow" >{{ item.content | fullTextFormat(50) }}</div>
      </div>
      <noDataImg v-if="list.length == 0"></noDataImg>
    </div>
    <div class="List" v-else v-loading="loading">
      <div v-for="(item,i) in list" :key='i' class="item" @click="goJob(item)">
        <span class="core"></span>
        <div class="tit2 textOverflow">{{ item.name || item.name_en }}-{{ item.city_name_zh || item.city_name_en }}</div>
        <div class="tit3 textOverflow" @click="goToCompany(item)">{{ item.name_zh || item.name_en }}</div>
      </div>
      <noDataImg v-if="list.length == 0"></noDataImg>
    </div>
    <div class="bot" v-if="total>5"  @click="goToList">
      <span>{{ bottomtxt }}</span>
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/right_core.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props:{
    tit:{
      type:String,
      default:""
    },
    loading:{
      type:Boolean,
      default:false
    },
    bottomtxt:{
      type:String,
      default:""
    },
    list:{
      type:Array,
      default:function(){
        return []
      }
    },
    total:{
      type:String | Number,
      default:0
    }
  },
  data() {
    return{
      activeName:""
    }
  },
  methods:{
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    goJob(item){
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id: item.id,
          }),
        },
      });
    },
    goToCompany(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id,
          }),
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    goToList(){
      if(this.tit == "人才库"){
        this.$router.push({
          path: "/talents/recruitment",
        })
      }else{
        this.$router.push({  //41 校企合作    42 招聘信息
          path: "/activitys",
          query: {
            content_type: this.tit=='校企合作'?41:42,
          }
        })
      }
    }

  }
}
</script>
<style lang="less" scoped>
.cBox{
  padding: 24px 16px 24px 20px;
  width: 384px;
  height: 308px;
  background: #FFFFFF;
  box-shadow: 0px 1px 5px 0px rgba(192, 209, 198, 0.5);
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  .List{
    min-height: 180px;
    position: relative;
    .tit2{
      width: 140px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }
    .tit2:hover{
      color: #15306F;
    }
    .tit3{
      width: 177px;
      font-size: 14px;
      font-weight: 400;
      color: #15306F;
      cursor: pointer;
    }
  }
  .tit{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    margin-bottom: 21px;
    display: inline-block;
  }
  .bot{
    position: absolute;
    bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span{
      padding-right:8px;
      color: #0E6A37;
    }

  }
  .item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .core{
      width: 6px;
      height: 6px;
      background: #333333;
      border-radius: 3px;
      margin-right:9px;
    }
    .tit{
      width: 310px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 0 !important;
      cursor: pointer;
    }

    .tit:hover{
      color: #0E6A37;
    }
  }
}
</style>