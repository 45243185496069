var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"homeIndexBanner"},[_c('div',{staticClass:"bannerContent"},[(_vm.dataList.length)?_c('div',{staticClass:"line"}):_vm._e(),_c('el-carousel',{attrs:{"dots":"none","trigger":"click","arrow":_vm.dataList.length > 1 ? 'hover' : 'never',"indicator-position":_vm.dataList.length > 1 ? '' : 'none'}},_vm._l((_vm.dataList),function(item,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"bannerInner",class:{
            pointer: item.link_url && item.extend && !item.extend.is_show,
          },style:({ 'background-image': 'url(' + item.url + ')' })},[(item.link_url && item.extend && !item.extend.is_show)?_c('div',{staticClass:"bannerHerf"},[(item.link_url && item.link_url.indexOf('http') == '0')?_c('a',{attrs:{"href":item.link_url,"target":"_blank","rel":"nofollow"}}):_c('a',{attrs:{"href":item.link_url,"rel":"nofollow"}})]):_vm._e(),(item.extend && item.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + item.extend.buttonStyle,style:({
              bottom: `${item.extend.bottom}%`,
              right: `${item.extend.right}%`,
              background: item.extend.backgroundColor,
              borderColor: item.extend.borderColor,
            })},[(item.link_url && item.link_url.indexOf('http') == '0')?_c('a',{style:({
                  fontSize: `${item.extend.remSize}px`,
                  color: item.extend.color,
                }),attrs:{"href":item.link_url,"target":"_blank","rel":"nofollow"}},[_vm._v(" "+_vm._s(item.extend.content))]):_c('a',{style:({
                  fontSize: `${item.extend.remSize}px`,
                  color: item.extend.color,
                }),attrs:{"href":item.link_url,"rel":"nofollow"}},[_vm._v(" "+_vm._s(item.extend.content))])]):_vm._e()])])}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }