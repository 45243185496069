<template>
  <div class="homeIndexBanner" >
    <div class="bannerContent">
      <div class="line" v-if="dataList.length"></div>
      <el-carousel
          dots="none"
          trigger="click"
          :arrow="dataList.length > 1 ? 'hover' : 'never'"
          :indicator-position="dataList.length > 1 ? '' : 'none'"
      >
        <el-carousel-item v-for="(item, index) in dataList" :key="index">
          <div
              class="bannerInner"
              :style="{ 'background-image': 'url(' + item.url + ')' }"
              :class="{
              pointer: item.link_url && item.extend && !item.extend.is_show,
            }"
          >
            <div
                class="bannerHerf"
                v-if="item.link_url && item.extend && !item.extend.is_show"
            >
              <a
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="item.link_url && item.link_url.indexOf('http') == '0'"
              ></a>
              <a :href="item.link_url" rel="nofollow" v-else></a>
            </div>
            <div
                v-if="item.extend && item.extend.is_show"
                class="bannerBtn"
                :class="'btnStyle' + item.extend.buttonStyle"
                :style="{
                bottom: `${item.extend.bottom}%`,
                right: `${item.extend.right}%`,
                background: item.extend.backgroundColor,
                borderColor: item.extend.borderColor,
              }"
            >
              <a
                  :style="{
                    fontSize: `${item.extend.remSize}px`,
                    color: item.extend.color,
                  }"
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="item.link_url && item.link_url.indexOf('http') == '0'"
              >
                {{ item.extend.content }}</a
              >
              <a
                  :href="item.link_url"
                  rel="nofollow"
                  :style="{
                    fontSize: `${item.extend.remSize}px`,
                    color: item.extend.color,
                  }"
                  v-else
              >
                {{ item.extend.content }}</a
              >
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  name: "homeIndexBanner",
  props: {
    //盒子撑高百分比
    paddingTop: {
      type: String,
      default: "24%",
    },
    //banner类型
    bannerType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataList: [],
      placeHoleder: "请输入成员名称",
      search_value: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        banner_type: this.bannerType,
      };
      // 获取协会的 banner 部分
      let res = await this.$store.dispatch(
          "baseStore/getAssociationBanner",
          params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
          item.extend.remSize = this.rootResize(item.extend.fontSize);
        });
        this.dataList = res.data;
      }
      // console.log(this.dataList);
    },
    rootResize(val) {
      var baseFontSize = val || 14;
      var baseWidth = 1920;
      var minWidth = 1200;
      var clientWidth =
          document.documentElement.clientWidth || window.innerWidth;
      var innerWidth = Math.max(Math.min(clientWidth, baseWidth), minWidth);
      var rem = clientWidth / (baseWidth / baseFontSize);
      rem = Math.round(innerWidth / (baseWidth / baseFontSize));
      return rem;
    },
    handleLink(link) {
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url);
      }
    },
    querySearchCompanyName(queryString, cb) {
      if (this.select == 1) {
        // 搜成员
        this.searchUser(queryString, cb);
      } else if (this.select == 2) {
        //搜活动
        this.searchActivity(queryString, cb);
      } else if (this.select == 3) {
        //搜职位
        this.searchJob(queryString, cb);
      } else if (this.select == 4) {
        //搜课程
        this.searchKC(queryString, cb);
      }
    },
    // 搜成员
    async searchUser(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.source = this.PJSource;
      params.usage = 2;
      params.is_vip = 1;
      let res = await this.$store.dispatch(
          "baseStore/company_associateSearch",
          params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    //搜活动
    async searchActivity(queryString, cb) {
      let params = {};
      params.name = queryString;
      (params.start = 0),
          (params.limit = 10),
          (params.source = this.PJSource),
          (params.user_id = this.USER_INFO.id);
      let res = await this.$store.dispatch(
          "baseStore/baseAssociation_event",
          params
      );
      let values;

      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    //搜职位
    async searchJob(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.nopage = 0;
      (params.source = this.PJSource), (params.user_id = this.USER_INFO.id);
      let res = await this.$store.dispatch(
          "baseStore/baseGetJobPositionList",
          params
      );
      let values;

      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      console.log(values);

      cb(values);
    },
  },
};
</script>

<style lang="less" scoped>
.homeIndexBanner {
  width: 792px;
  height: 446px;
  position: relative;
  margin-bottom: 24px;
  .mark{
    position: absolute;
    bottom: 0px;
    height: 40px;
    width: 792px;
    z-index: 10;
    background: #000000;
    opacity: 0.4;
  }
  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .line{
      width: 100%;
      height: 40px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #000000;
      opacity: 0.4;
      z-index: 99;
    }
    .el-carousel {
      height: 100%;
      /deep/ .el-carousel__container {
        height: 100% !important;
        .bannerInner {
          height: 100%;
          background-size: 100% 100%;
          // cursor: pointer;
          position: relative;
          .bannerHerf {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            a {
              display: block;
              height: 100%;
              width: 100%;
            }
          }
          .bannerBtn {
            position: absolute;
            z-index: 20;
            padding: 0 20px;
            border: 1px solid;
            border-radius: 4px;
            transform: translate(50%, 50%);
            a{
              display: block;
              height: 100%;
              width: 100%;
              padding: 12px 0px;
            }
            &.btnStyle1 {
              border-radius: 0px;
            }
            &.btnStyle2 {
              border-radius: 4px;
            }
            &.btnStyle3 {
              border-radius: 999px;
            }
          }
        }
      }
      /deep/ .el-carousel__indicators--horizontal{
        left: 90%;
        z-index: 999;
      }
      /deep/ .el-carousel__button {
        z-index: 15;
        width: 12px;
        height: 12px;
        background: #ccc;
        border-radius: 50%;
      }
      /deep/ .el-carousel__arrow {
        width: 50px;
        height: 50px;
        font-size: 45px;
        background: transparent !important;
        i {
          font-weight: bold;
        }
      }
      /deep/ .el-carousel__indicator.is-active button {
        background-color: #fff !important;

      }
    }
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
