<template>
  <div>
    <div class="ctitbox flex">
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu.png" alt="">
      <span>协会服务</span>
    </div>
    <div class="three_bars">
      <gongshi class="three_item_gs" :barType='1' />
      <bars class="three_item" :activetab='BarIndex' :tabarr='BarArr'  />
      <advertisement class="three_item_gs"/>
    </div>
  </div>
</template>
<script>
import bars from './bars'
import gongshi from './gongshi.vue'
import advertisement from './advertisement.vue'
export default {
  components:{
    bars,
    gongshi,
    advertisement
  },
  data(){
    return{
      BarIndex:"37",
      BarArr:[
        {
          label:"专业知识",
          name:"37"
        },
        {
          label:"活动通知",
          name:"1"
        },
        {
          label:"智慧通关",
          name:"52"
        },
        {
          label:"经贸交流",
          name:"40"
        }
      ]

    }
  },
}
</script>
<style lang="less" scoped>
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ctitbox{
  width: 100%;
  span{
    font-size: 28px;
    font-weight: bold;
    color: #222222;
    line-height: 32px;
    padding-left:10px;
  }
}
.three_bars{
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  .three_item{
    width: 547px;
  }
  .three_item_gs{
    width: 280px;
  }
}
</style>