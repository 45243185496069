<template>
  <div>
    <div class="tit">公示专栏</div>
    <div class="itemBox">
      <div v-for="(item , i) in imgArr" :key="i" class="item" @click="handleItem(item)">
        <img :src="item.url" alt="">
        <div>
          <p class="green">{{ item.txt }}</p>
          <p class="gray" v-if="i==3">（地区优秀会员评价）</p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data(){
    return{
      imgArr:[
        {
          value:0,
          // router:"/informationRelease?content_type=30",
          txt:"行业收费目录清单",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          // router:"/informationRelease?content_type=31",
          txt:"团体标准公示",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          router:"/companyCatalog",
          txt:"会员名录",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          // router:"/informationRelease?content_type=32",
          txt:"跨境贸易服务商公示",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          // router:"/informationRelease?content_type=33",
          txt:"通关时效排行榜",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          // router:"/informationRelease?content_type=34",
          txt:"荣誉先进单位个人公示",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          // router:"/informationRelease?content_type=35",
          txt:"行业黑名单",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
        {
          value:0,
          // router:"/informationRelease?content_type=36",
          txt:"协会信息公开",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/gongshi_1.png"
        },
      ]
    }
  },
  methods:{
    handleItem(item){
      if (item.router) {
        this.$router.push(item.router)
      }
    }
  }


}
</script>
<style lang="less" scoped>

.tit{
  padding-top:10px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-bottom:8px;
  border-bottom:1px solid #DDDDDD;
}
.itemBox{
  display: flex;
  flex-wrap:wrap;
  box-sizing: border-box;
  padding-top: 14px;
  .item{
    cursor: pointer;
    width: 201px;
    height: 73px;
    background: #E9F3F0;
    box-sizing: border-box;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 150px;
      padding-left: 10px;
      .green{
        font-size: 14px;
        font-weight: 400;
        color: #15306F;
      }
      .gray{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .item:nth-child(2n-1){
    margin-right:5px;
  }
}
</style>