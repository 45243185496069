import { render, staticRenderFns } from "./bars.vue?vue&type=template&id=dbf63f24&scoped=true"
import script from "./bars.vue?vue&type=script&lang=js"
export * from "./bars.vue?vue&type=script&lang=js"
import style0 from "./bars.vue?vue&type=style&index=0&id=dbf63f24&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbf63f24",
  null
  
)

export default component.exports