<template>
  <div class="cpmBox">
    <div class="ctitbox flex">
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tj_server.png" alt="">
      <span>推荐服务</span>
    </div>
    <div class="itemBox">
      <div class="creditBanner" v-for="(item,index) in list" :key="index">
        <div class="creditBannerImg">
          <div
              class="bannerInner"
              :class="{
            pointer:
              item.link_url &&
              item.extend &&
              !item.extend.is_show,
          }"
              :style="{ 'background-image': 'url(' + item.img_url + ')' }"
          >
            <div
                class="bannerHerf"
                v-if="
              item.link_url &&
              item.extend &&
              !item.extend.is_show
            "
            >
              <a
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="
                item.link_url &&
                item.link_url.indexOf('http') == '0'
              "
              ></a>
              <a :href="item.link_url" rel="nofollow" v-else></a>
            </div>
            <div
                v-if="item.extend && item.extend.is_show"
                class="bannerBtn"
                :class="'btnStyle' + item.extend.buttonStyle"
                :style="{
              bottom: `${item.extend.bottom}%`,
              right: `${item.extend.right}%`,
              background: item.extend.backgroundColor,
              borderColor: item.extend.borderColor,
            }"
            >
              <a
                  :style="{
                fontSize: `0.${item.extend.fontSize}rem`,
                color: item.extend.color,
              }"
                  :href="item.link_url"
                  target="_blank"
                  rel="nofollow"
                  v-if="
                item.link_url &&
                item.link_url.indexOf('http') == '0'
              "
              >
                {{ item.extend.content }}</a
              >
              <a
                  :href="item.link_url"
                  rel="nofollow"
                  :style="{
                fontSize: `0.${item.extend.fontSize}rem`,
                color: item.extend.color,
              }"
                  v-else
              >
                {{ item.extend.content }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <noDataImg v-if="!list.length"></noDataImg>
    </div>
    <div class="linkBox">
      <div>
        <span class="link">友情链接:</span>
        <el-link class="link" :underline="false" target="_blank" :href="item.url" v-for="(item,i) in linkArr" :key='i'>
          {{ item.txt }}
        </el-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imgarr: [
        {
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tj_server.png",
          img: ""
        },
        {
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tj_server.png",
          img: ""
        },
        {
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tj_server.png",
          img: ""
        },
        {
          url: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tj_server.png",
          img: ""
        },
      ],
      linkArr: [
      //   {
      //     url: 'http://www.mofcom.gov.cn/',
      //     txt: '国家商务部'
      //   },
      //   {
      //     url: 'http://www.moc.gov.cn/',
      //     txt: '国家交通部'
      //   },
      //   {
      //     url: 'http://www.tjcoc.gov.cn/',
      //     txt: '天津商务部'
      //   },
      //   {
      //     url: 'http://www.tjport.gov.cn/',
      //     txt: '天津口岸办'
      //   },
      //   {
      //     url: 'http://tianjin.customs.gov.cn/',
      //     txt: '天津海关'
      //   },
      ],
      list:[]
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    async getList() {
      let params = {
        language: this.LOCALE === "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "17,18,19,20",
      };
      let res = await this.$store.dispatch(
          "API_index/getAdvertisingList",
          params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.extend = JSON.parse(item.extend);
        });
        this.list=res.data
      }
    },
  }

}
</script>
<style lang="less" scoped>
.cpmBox {
  height: 505px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ctitbox {
  padding-top: 64px;
  padding-bottom: 40px;
  width: 100%;

  span {
    font-size: 28px;
    font-weight: bold;
    color: #222222;
    line-height: 32px;
    padding-left: 10px;
  }
}

.itemBox {
  width: 1200px;
  height: 188px;
  margin: auto;
  display: flex;
  position: relative;

  .creditBanner {
    width: 282px;
    height: 188px;
    position: relative;
    margin-right: 24px;
    &:last-child{
      margin-right: 0;
    }
    .creditBannerImg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .bannerInner {
        height: 100%;
        background-size: 100% 100%;
        position: relative;
        .bannerHerf {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          a {
            display: block;
            height: 100%;
            width: 100%;
          }
        }
        .bannerBtn {
          position: absolute;

          padding: 0px 20px;
          border: 1px solid;
          border-radius: 4px;
          transform: translate(50%, 50%);
          &.btnStyle1 {
            border-radius: 0px;
          }
          &.btnStyle2 {
            border-radius: 4px;
          }
          &.btnStyle3 {
            border-radius: 999px;
          }
        }
      }
    }
  }
}

.linkBox {
  margin-top: 121px;
  border-top: 1px solid #E5E5E5;
  height: 60px;

  > div {
    width: 1200px;
    height: 100%;
    margin: auto;
    color: #333333;
    display: flex;
    align-items: center;

    .link {
      padding-right: 30px;
    }
  }
}
</style>