<template>
  <div>
    <div class="ctitbox flex">
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/hangyezilv_label.png" alt="">
      <span>行业自律</span>
    </div>
    <div class="three_bars">
      <bars class="three_item" :activetab='leftBarIndex' :tabarr='leftBarArr' />
      <bars class="three_item" :activetab='rightBarIndex' :tabarr='rightBarArr'  />
      <gongshi class="three_item_gs" />
    </div>
  </div>
</template>
<script>
import bars from './bars.vue'
import gongshi from './gongshi.vue'
export default {
  components:{
    bars,
    gongshi
  },
  data(){
    return{
      leftBarIndex:"48",
      leftBarArr:[
        {
          label:"规范申报",
          name:"48"
        },
        {
          label:"阳光价格",
          name:"49"
        }
      ],
      rightBarIndex:"50",
      rightBarArr:[
        {
          label:"行业竞赛",
          name:"50"
        },
        {
          label:"标准化建设",
          name:"51"
        }
      ]

    }
  },
}
</script>
<style lang="less" scoped>
.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.ctitbox{
  width: 100%;
  span{
    font-size: 28px;
    font-weight: bold;
    color: #222222;
    line-height: 32px;
    padding-left:10px;
  }
}
.three_bars{
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  .three_item{
    width: 336px;
  }
  .three_item_gs{
    width: 407px;
  }
}
</style>