<template>
  <div class="box">
    <div class="boxLeft">
      <div class="top">
        <el-tabs v-model="activeName" @tab-click="handleClickCenterTab">
          <el-tab-pane v-for="(item, index) in tags" :name="item.name" :key="index">
            <span slot="label" class="titleBox">
              <span class="title">{{ item.label }}</span>
            </span>
          </el-tab-pane>
        </el-tabs>
        <div class="moreBox pointer" @click="handleMore">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right color128144"></i>
        </div>
      </div>
      <div v-loading="leftLoading" class="bottonContainer">
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
        <div class="bottom" v-if="leftList.length > 0">
          <div class="listRight">
            <div v-for="(item, index) in leftList" :key="index" class="newsConten">
              <div  class="nItem textOverflow" @click="goDetail(item)" >
                <div class="itemCore"></div>
                <span>{{item.title | textFormat}}</span>
                <div class="date">{{ item.created_time | secondFormat("LL") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import advertising from "~tcb/components/index/home/advertising";

export default {
  name: "activity",
  components: { advertising },
  data() {
    return {
      activeName: "4",
      tags: [
        {
          label: "政策资讯",
          name: "4",
        },
        {
          label: "党建动态",
          name: "13",
        },
        {
          label: "行业动态",
          name: "3",
        },

      ],
      leftList: [],
      leftLoading: false,
      advertising: {},
    };
  },
  created() {
    this.getLeftList();
  },
  methods: {
    async getLeftList() {
      let params = {
        content_type: this.activeName,
        source: this.PJSource,
        nopage: 0,
        limit: 9,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      };
      this.leftLoading = true;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      this.leftLoading = false;
      if (res.success) {
        this.leftList = res.data;
      }
    },
    goDetail(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    handleClickCenterTab(e) {
      this.getLeftList();
    },
    handleMore() {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: this.activeName * 1,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.newsConten{
  .nItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 13px 0;
    .itemCore{
      width: 6px;
      height: 6px;
      background: #333333;
      border-radius: 3px;
    }
    span{
      text-align: left;
      width: 262px;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover{
        color: #0E6A37;
      }
    }
    span:hover{
      color: #0E6A37;
    }
    .date{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }

}

.box {
  display: flex;
  justify-content: space-between;

  .boxLeft {
    width: 390px;
    height: 466px;
    box-sizing: border-box;

    .top {
      padding-top: 4px;
      width: 100%;
      height: 46px;
      overflow: hidden;
      position: relative;
      /deep/ .el-tabs__nav{
        width: 300px;
        display: flex;
        justify-content: space-between;
        .el-tabs__active-bar{
          width: 72px!important;
        }
      }

      .titleBox {
        //padding-left: 16px;
        //padding-right: 16px;
      }

      /deep/ .el-tabs__nav-wrap::after {
        background-color: #dcdfe6;
        border-bottom: 2px;
      }

      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
        padding-right: 40;
      }

      /deep/ .el-tabs__item {
        font-size: 18px;
        font-weight: 800;
      }


      .moreBox {
        position: absolute;
        right: 0;
        top: 10px;
        padding: 5px 0 5px 19px;

        .more {
          font-size: 14px;
          color: #128144;
        }
      }
    }

    .bottonContainer {
      min-height: 333px;
      position: relative;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      .listLeft {
        width: 364px;

        .imgBox {
          width: 352px;
          height: 175px;
          //background: #cccccc;
          .img {
            width: 352px;
            height: 175px;
          }
        }

        .itemTitle {
          padding: 8px 0 8px;
          font-size: 16px;
          color: #1f292e;
          font-weight: bold;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          max-width: 364px;
        }

        .date {
          margin-top: 6px;
          color: #7d8695;
          font-size: 14px;
        }

        .itemContent {
          color: #7d8695;
          font-size: 14px;

        }

        .moreBox {
          margin-top: 14px;
          display: flex;
          align-items: center;
          width: 80px;

          .moreText {
            color: #909399;
            margin-right: 6px;
          }

          .moreIconBox {
            width: 16px;
            height: 16px;
            background: #192f59;
            border-radius: 8px;
            text-align: center;
            line-height: 16px;

            .moreIcon {
              font-size: 10px;
              color: #ffffff;
            }
          }
        }
      }

      .listRight {
        width: 400px;
        position: relative;
        min-height: 303px;

        .rightItem {
          .itemContent {
            color: #7d8695;
            font-size: 14px;
            line-height: 26px;
            border-bottom: 1px solid #dcdfe6;
            padding-bottom: 13px;
            padding-top: 14px;

            .itemTitle {
              width: 400px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
          }

          .date {
            font-size: 14px;
            color: #7d8695;
            text-align: right;
          }
        }

        .rightItem:nth-child(2) .itemContent {
          padding-top: 0;
        }
      }
    }
  }

  .boxRight {
    width: 364px;
    height: 426px;
    padding-top: 47px;
    .top {
      width: 100%px;
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 8px 0 20px;
      justify-content: space-between;
      background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/indexActivityLeft.png");
      background-size: 100% 100%;
      .title {
        font-size: 18px;
        font-weight: 800;
        color: #ffffff;
      }
      .more {
        font-size: 14px;
        color: #ffffff;
      }
    }
    .bottom {
      height: 256px;
      background: #f6faff;
      padding: 20px 28px 26px 20px;
      position: relative;
      .font {
        color: #1f292e;
        width: 316px;
        margin-bottom: 12px;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }
}

.color909399 {
  color: #909399;
}
</style>