<template>
  <div>
    <div v-if="barType==1">
      <div class="tit">下载专区</div>
      <div class="itemBox">
        <div v-for="(item , i) in imgArr" :key="i" class="item">
          <img :src="item.url" alt="">
          <div>
            <p class="green">{{ item.txt }}</p>
            <p class="gray" v-if="i==3">（地区优秀会员评价）</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="tit">合作招募</div>
      <div class="itemBox">
        <div v-for="(item , i) in list" :key="i" class="item">
          <img :src="item.url" alt="">
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props:{
    barType:{
      type:String | Number,
      default:1
    }
  },
  data(){
    return{
      imgArr:[
        {
          value:0,
          txt:"课件专区",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu_1.png"
        },
        {
          value:0,
          txt:"专业书籍",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu_2.png"
        },
        {
          value:0,
          txt:"《口岸通关》电子刊",
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu_3.png"
        },
      ],
      list:[
        {
          value:1,
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu_3.png"
        },
        {
          value:2,
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu_3.png"
        },
        {
          value:3,
          url:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/xiehuifuwu_3.png"
        },
      ]
    }
  },


}
</script>
<style lang="less" scoped>

.tit{
  padding-top:10px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  padding-bottom:8px;
  border-bottom:1px solid #DDDDDD;
}
.itemBox{
  display: flex;
  flex-wrap:wrap;
  box-sizing: border-box;
  padding-top: 14px;
  .item{
    cursor: pointer;
    width: 282px;
    height: 73px;
    background: #E9F3F0;
    background: #E9F3F0;
    box-sizing: border-box;
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
      width: 150px;
      padding-left: 10px;
      .green{
        font-size: 14px;
        font-weight: 400;
        color: #15306F;
      }
      .gray{
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>